@use 'sass:map';
@use '@angular/material' as mat;

@mixin checkbox($theme) {
  .mat-mdc-checkbox .mdc-form-field label {
    font-weight: 500;
    margin-bottom: 0;
  }
}

@mixin theme($theme) {
  @include checkbox($theme);
}
