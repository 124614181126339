@use 'sass:map';
@use '@angular/material' as mat;

@mixin dialog($theme) {
  .mat-mdc-dialog-surface {
    padding: 15px;
  }
}

@mixin theme($theme) {
  @include dialog($theme);
}
