@use 'sass:map';
@use '@angular/material' as mat;

@mixin tabs($theme) {
  .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs
    > .mat-mdc-tab-header
    .mat-mdc-tab {
    flex-grow: 0 !important;
  }
}

@mixin theme($theme) {
  @include tabs($theme);
}
