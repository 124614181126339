@use 'sass:map';
@use '@angular/material' as mat;

@mixin card($theme) {
  .mat-mdc-card {
    padding: 1em;

    .mat-mdc-card-content {
      padding: 0 !important;
    }

    .mat-mdc-card-content:last-child {
      padding: 0 !important;
    }
  }
}

@mixin theme($theme) {
  @include card($theme);
}
