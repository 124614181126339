@use 'sass:map';
@use '@angular/material' as mat;

@mixin button($theme) {
  button[mat-raised-button],
  button[mat-stroked-button] {
    height: 36px !important;

    .mdc-button__label {
      font-weight: 600;
    }

    .mat-icon {
      width: 1.5em;
      height: 1.5em;
      font-size: 1.5em;
      line-height: 1.5em;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 -0.5em;
    }
  }

  button[mat-icon-button] {
    .mat-mdc-button-persistent-ripple {
      border-radius: 0 !important;
    }
  }
}

@mixin theme($theme) {
  @include button($theme);
}
