@use '@angular/material' as mat;
@use './button.material.scss' as button;
@use './card.material.scss' as card;
@use './tabs.material.scss' as tabs;
@use './checkbox.material.scss' as checkbox;
@use './dialog.material.scss' as dialog;

$audit-blue-palette: (
  100: #4da1df,
  500: #1071c1,
  700: #0f65a9,
  contrast: (
    500: white,
  ),
);

$primary-palette: mat.define-palette($audit-blue-palette);
$accent-palette: mat.define-palette(mat.$indigo-palette);
$warn-palette: mat.define-palette(mat.$red-palette);
$typography-config: mat.define-typography-config(
  $headline-6:
    mat.define-typography-level(
      $font-family: 'Poppins',
      $font-weight: 400,
      $font-size: 1.2em,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $subtitle-2:
    mat.define-typography-level(
      $font-family: 'Poppins',
      $font-weight: 400,
      $font-size: 1em,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $body-2:
    mat.define-typography-level(
      $font-family: 'Poppins',
      $font-weight: 400,
      $font-size: 1em,
      $line-height: 1,
      $letter-spacing: normal,
    ),
);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette,
    ),
    typography: $typography-config,
  )
);

/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated */
@include mat.all-component-themes($theme);
@include button.theme($theme);
@include card.theme($theme);
@include tabs.theme($theme);
@include checkbox.theme($theme);
@include dialog.theme($theme);
@include mat.all-component-densities(-1);
